<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script>
  import './App.scss';

  export default {
    name: 'App',
  }
</script>