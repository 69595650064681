<template>
	<a-modal class="component-product-modal" v-model:open="open" @ok="onOk" @cancel="onClose">
		<a-row class="top">
			<a-col class="left" :span="24" :md="10">
				<a-carousel arrows dots-class="slick-dots slick-thumb">
					<template #customPaging="props">
						<a>
							<img :src="getImgUrl(props.i)" />
						</a>
					</template>
					<div v-if="data.images" v-for="item in data.images.length" :key="item">
						<img :src="getImgUrl(item - 1)" />
					</div>
				</a-carousel>
			</a-col>
			<a-col class="right" :span="24" :md="14">
				<ul>
					<li class="title">{{ data.title }}</li>
					<li v-for="value in data.description">{{ value }}</li>
					<li>
						<a-row justify="space-between">
							<a-col class="point">
								<span v-if="data.sale_price != undefined && data.sale_price > 0">
									<del style="color: gray">點數 {{ data.price }}</del>
									&nbsp;
									【{{ data.sale_price }} 點】
								</span>
								<span v-else-if="data.sale_price != undefined && data.sale_price == 0">
									<del style="color: gray">點數 {{ data.price }}</del>
									&nbsp;
									免費！
								</span>
								<span v-else>點數 {{ data.price }}</span>
								
							</a-col>
							<a-col class="point">可用點數 {{ user.balance }}</a-col>
						</a-row>
					</li>
					<li class="number">
						<a-input-number v-model:value="data.amount" :min="1">
							<template #addonBefore>
								<a-button @click="onAmount(-1)">-</a-button>
							</template>
							<template #addonAfter>
								<a-button @click="onAmount(+1)">+</a-button>
							</template>
						</a-input-number>
					</li>
					<li class="button">
						<a-row>
							<a-col :span="12">
								<a-button @click="onAddCar" ghost>加入購物車</a-button>
							</a-col>
							<a-col :span="12">
								<a-button @click="onBuy">立即購買</a-button>
							</a-col>
						</a-row>
					</li>
				</ul>
			</a-col>
		</a-row>

		<a-row class="desciption" justify="center">
			<a-col>商品介紹</a-col>
		</a-row>

		<div>
			<div class="desciption2">商品介紹</div>
			<div v-html="data.content"></div>
		</div>

		<template #footer>
			<a-row align="middle">
				<a-col :span="4">
					<router-link to="/car">
	                    <a-badge :count="car.length">
	                        <img src="@/assets/car.svg" />
	                    </a-badge>
	                </router-link>
				</a-col>
				<a-col :span="10">
					<a-button @click="onAddCar" ghost>加入購物車</a-button>
				</a-col>
				<a-col :span="10">
					<a-button @click="onBuy">立即購買</a-button>
				</a-col>
			</a-row>
		</template>
	</a-modal>
</template>

<script>
	import './modal.scss';
	import { ref, reactive, watch, computed } from 'vue';
	import { useStore } from 'vuex';
	import router from '@/router/index';
	import { addCart, member } from '@/api-core/api-core';
	import { message } from 'ant-design-vue';

	export default {
		name: 'Modal',
		props: ['data', 'open'],

		setup(props, context) {
			const imageUrl = process.env.VUE_APP_STATIC_URL + '/images/product/';
			const store = useStore();
			const data = ref({});
			const open = ref(false);

			const user = reactive({
                balance : computed(() => store.state.user.balance),
            });
            const car = reactive(computed(() => store.state.car.list || []));

			const onOk = () => {
				context.emit('hide');
	        };
	        const onClose = () => {
				context.emit('hide');
	        };
	        const onAmount = num => {
	        	data.value.amount += num;
	        	if (data.value.amount < 1) {
	        		data.value.amount = 1;
	        	}
	        };
	        const onAddCar = () => {
	        	addCart({product_id: data.value.id, amount: data.value.amount}).then(res => {
	        		if (res.code == 30000) {
	        			store.dispatch('car/get');
	        			message.success('添加成功');
	        			onClose();
	        		}
	        		else {
	        			message.error('添加失敗');
	        		}
	        	});
	        };
	        const onBuy = () => {
	        	localStorage.buy = JSON.stringify(data.value);
	        	router.push({ path: '/car', query: {type: 'buy'} });
	        };
	        const getImgUrl = i => {
	        	return imageUrl + data.value.images[i];
	        };

	        watch(() => props.data, (n, o) => {
	        	store.dispatch('user/member');

	        	if (typeof(n.description) == 'string') {
	        		n.description = n.description.split('\n')
	        	}
	        	data.value = n;
	        	data.value.amount = 1;

	        });

	        watch(() => props.open, (n, o) => {
	            open.value = n;
	        });

			return {
				open, onOk, onClose, imageUrl, onAmount, onAddCar, onBuy, getImgUrl, user, car
			}
		},
	}
</script>

<style scoped>
/* For demo */
:deep(.slick-dots) {
  position: relative;
  height: auto;
}
:deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
:deep(.slick-arrow) {
  display: none !important;
}
:deep(.slick-thumb) {
  bottom: 0px;
}
:deep(.slick-thumb li) {
  width: 60px;
  height: 45px;
}
:deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  display: block;
}
:deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>