<template>
    <div class="component-home">
        <Banner />
        <Product :list="product" @showModal="showProductModal" />
        <div class="more" :page="page" :totalPage="totalPage" v-if="page < totalPage" @click="nextProduct()">
            <span class="btn">查看更多</span>
            <LoadingOutlined class="load" />
        </div>
        <!-- <div class="limit">限量商品</div> -->
        <!-- <Product :list="limitProduct" @showModal="showProductModal" /> -->
        <ProductModal :data="productModal" :open="productModalOpen" @hide="hideProductModal" />
    </div>
</template>

<script>
    import './home.scss';
    import { ref, watch, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import { products } from '@/api-core/api-core';
    import { LoadingOutlined } from '@ant-design/icons-vue';

    import Banner from '@/components/layouts/banner/banner.vue';
    import Product from '@/components/product/product.vue';
    import ProductModal from '@/components/product/modal.vue';

    export default {
        name: 'Home',
        components: {
            Banner, Product, ProductModal, LoadingOutlined
        },

        setup() {
            const route = useRoute();

            let cate_id = 0;
            let page = ref(1);
            const size = 12;
            const totalPage = ref(0);
            const name = ref(route.query.search);

            const product = ref([]);
            const limitProduct = ref([]);
            const productModal = ref({});

            const productModalOpen = ref(false);

            const showProductModal = (key) => {
                productModal.value = product.value[key] || limitProduct.value[key];
                productModalOpen.value = true;
            };

            const hideProductModal = () => {
                productModal.value = {};
                productModalOpen.value = false;
            };

            const nextProduct = () => {
                page.value ++;
                getProduct();
            };

            const getProduct = () => {
                products({cate_id: cate_id, name: name.value, page: page.value, size: size}).then(res => {
                    totalPage.value = res.data.pagination.total_page;
                    for (let key in res.data.data) {
                        product.value.push(res.data.data[key]);
                    }
                    isScrollLoad = false;
                });
            };

            const getLimitProduct = () => {
                products({cate_id: cate_id, page: 1, size: size}).then(res => {
                    limitProduct.value = res.data.data;
                });
            };

            let isScrollLoad = false;
            const scrollLoad = () => {
                const main = document.querySelector('.ant-layout-content');

                main.addEventListener('scroll', event => {
                    // if (
                    //     ! document.querySelector('.component-product') ||
                    //     window.innerWidth > 767) {
                    //     return ;
                    // }
                    if (page.value < totalPage.value && ! isScrollLoad && 0 > (
                        main.scrollHeight - main.scrollTop - main.clientHeight -
                        document.querySelector('.more').clientHeight -
                        document.querySelector('.component-layout-footer-service').clientHeight
                    )){
                        isScrollLoad = true;
                        nextProduct();
                    }
                });
            }

            watch(() => route.query.cate_id, (n, o) => {
                cate_id = n;
                page = 1;
                product.value = [];
                name.value = '';
                getProduct();
            });
            watch(() => route.query.search, (n, o) => {
                cate_id = 0;
                page = 1;
                product.value = [];
                name.value = n;
                getProduct();
            });

            onMounted(() => {
                getProduct(); 
                scrollLoad();
                // getLimitProduct();
            });

            return {
                product, limitProduct, productModal, productModalOpen, showProductModal, hideProductModal, nextProduct, page, totalPage
            };
        }
    };
</script>