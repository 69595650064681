import { createStore } from 'vuex';
import user from './modules/user';
import car from './modules/car';
import activity from './modules/activity';
import createPersistedState from "vuex-persistedstate";

export default createStore({
	plugins: [createPersistedState()],
	modules: {
		user, car, activity, 
	},
});
