<template>
    <div class="component-layout-header">
        <a-row>
            <a-col :span="3" :md="8" class="left">
                <a class="item" @click="onClickItem">
                    <img src="@/assets/item.svg" />
                </a>
            </a-col>
            <a-col :span="6" :md="8" class="center">
                <a href="/" class="logo">
                    <img src="@/assets/logo3.png" />
                </a>
            </a-col>
            <a-col :span="15" :md="8" class="right">
                <router-link class="user" to="/user">
                    <img src="@/assets/user.svg" />
                </router-link>
                <router-link class="car" to="/car">
                    <a-badge :count="getAmount(car)">
                        <img src="@/assets/car.svg" />
                    </a-badge>
                </router-link>
                <img class="search" src="@/assets/search.svg" @click="showModal"/>
                <router-link class="user_point" to="/user/point">
                    <span>可用點數</span>
                    <span>{{ user.balance }}</span>
                </router-link>
            </a-col>
        </a-row>
        <a-row class="menu" justify="center">
            <Menu mode="vertical" />
        </a-row>

        <Search :open="searchOpen" @hide="hideModal" />
    </div>
</template>

<script>
    import './header.scss';
    import { ref, reactive, computed } from 'vue';
    import { useStore } from 'vuex';
    import { getCart, member } from '@/api-core/api-core';
    import Menu from '@/components/layouts/menu/menu.vue';
    import Search from '@/components/layouts/search/search.vue';

    export default {
        name: 'Header',
        components: {
            Menu, Search,
        },
        setup(props, context) {
            const store = useStore();
            const carCount = ref(0);
            const searchOpen = ref(false);
            const user = reactive({
                balance : computed(() => store.state.user.balance),
            });
            const car = reactive(computed(() => store.state.car.list));

            const onClickItem = () => {
                context.emit('onShow');
            };

            const getAmount = (data) => {
                let amount = 0;
                for (let key in data) {
                    let item = data[key];
                    amount += item.amount;
                }
                return amount;
            }

            const showModal = () => {
                searchOpen.value = true;
            }

            const hideModal = () => {
                searchOpen.value = false;
            }

            return {
                car, user, onClickItem, getAmount, searchOpen, showModal, hideModal
            };
        }
    };
</script>