<template>
	<div class="component-product">
		<a-row class="list" :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
			<a-col class="item" :span="12" :md="6" v-for="(item, key) in list" @click="showModal(key)">
				<div class="imgBox">
					<img v-if="item.images != null && item.images.length > 0" :src=" imageUrl + item.images[0]" />
					<PictureOutlined class="emptyImg" v-else />
				</div>
				<div class="title">
					{{ item.name }}
				</div>
				<div class="point">
					<span v-if="item.sale_price != undefined && item.sale_price > 0">
						<del style="color: gray">【{{ item.price }} 點】</del>
						<br />
						【{{ item.sale_price }} 點】
					</span>
					<span v-else-if="item.sale_price != undefined && item.sale_price == 0">
						<del style="color: gray">【{{ item.price }} 點】</del>
						<br />
						免費！
					</span>
					<span v-else>【{{ item.price }} 點】</span>
					<!-- <span>點數{{ item.price }}</span> -->
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import './product.scss';
	import { ref, watch } from 'vue';
    import { PictureOutlined } from '@ant-design/icons-vue';

	export default {
		name: 'Product',
		props: ['list'],
		components: {
            PictureOutlined, 
        },
		setup(props, context) {
			const imageUrl = process.env.VUE_APP_STATIC_URL + '/images/product/';
			const list = ref(props.list);

			const showModal = (key) => {
				context.emit('showModal', key);
			};

			watch(
	            () => props.list,
	            (n, o) => {
	                list.value = n;
	            }
	        );

			return {
				list, showModal, imageUrl
			};
		},

	}
</script>