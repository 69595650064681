<template>
	<a-modal class="component-layout-search" v-model:open="open" title="" @ok="onOk" @cancel="onClose" okText="搜尋" cancel-text="取消">
        <a-input v-model:value="search" placeholder="搜尋" />
    </a-modal>
</template>

<script>
    import './search.scss';
    import { ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    export default {
        name: 'Search',
        props: ['open'],
        setup(props, context) {
            const route = useRoute();
            const router = useRouter();
            const search = ref(route.query.search);
            const open = ref(false);


            const onOk = () => {
            	router.push({query: {search: search.value}});
				context.emit('hide');
	        };
	        const onClose = () => {
	        	search.value = '';
                router.push({query: {search: search.value}});
				context.emit('hide');
	        };


			watch(() => props.open, (n, o) => {
	            open.value = n;
	        });

            return {
                search, open, onOk, onClose
            };
        }
    };
</script>