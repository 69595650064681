import axios from 'axios';
import store from '@/store/index';
import router from '@/router/index';
import i18n from '@/locales/index';
import { message } from 'ant-design-vue';

const api = axios.create({
    baseURL: "/api/web",
});
api.defaults.withCredentials = true;
api.interceptors.request.use( config => {
    // if (config.url != '/get_token' && ! localStorage.token) {
    if (config.url != '/get_token' && ! store.state.user.token) {
        router.push({ path: '/' });
        return Promise.reject('token null');
    }
    else {
        // config.headers.Authorization = `Bearer ${ localStorage.token }`;
        config.headers.Authorization = `Bearer ${ store.state.user.token }`;
        return config;
    }
}, err => {
    Promise.reject(err);
});

api.interceptors.response.use( res => {
    if( res.status == 200 ) {
        var res_data = res.data;
        if( !res_data.hasOwnProperty("code") ) {
            return Promise.reject(res);
        }
        var code = res_data.code;
        if (code == 30001 || code == 30002) {
            // localStorage.token = '';
            store.dispatch("/user/setToken", {token: ''});

            router.push({ path: '/' });
            return Promise.reject(res);
        }
        if( code != 30000 ) {
            message.error(res.data.msg);
            return Promise.reject(res);
        }
        return Promise.resolve(res.data);
    }

    return Promise.reject(res);
}, err => {
    if (err != 'token null') {
        if( err.response.status == 401 ) {
            message.info(i18n.global.t('error.'+err.response.data.msg));
            router.push({ path: '/login' });
        }
        else if (err.response.status == 503) {
            router.push({ path: '/maintain' });
        }
        else {
            message.error(i18n.global.t('error.something wrong'));
        }
    }
    return Promise.reject(err);
});

export const getToken = (params) => api.post("/get_token", params);
export const member = () => api.get("/member");
export const editMember = (params) => api.post("/edit_member", params);
export const about = () => api.get("/about");
export const buyNote = () => api.get("/buy_note");
export const privacyPolicy = () => api.get("/privacy_policy");
export const productReturn = () => api.get("/product_return");
export const pointsRule = () => api.get("/points_rule");
export const cates = () => api.get("/cates");
export const products = (params) => api.get("/products", {params});
export const carousels = (params) => api.get("/carousels", {params});
export const getCart = (params) => api.get("/get_cart", {params});
export const addCart = (params) => api.post("/add_cart", params);
export const editCart = (params) => api.post("/edit_cart", params);
export const deleteCart = (params) => api.post("/delete_cart", params);
export const buy = (params) => api.post("/buy", params);
export const pointsDetail = (params) => api.get("/points_detail", {params});
export const orders = (params) => api.get("/orders", {params});
export const orderCancel = (params) => api.post("/order_cancel", params);
export const activity = (params) => api.get("/activity", {params});
export const playActivity = (params) => api.post("/activity/play", params);
export const activityOrder = (params) => api.get("/activity/win_record", {params});
export const redeamActivityOrder = (params) => api.post("/activity/redeem", params);