import { activity } from '@/api-core/api-core';

export default {
    namespaced: true,
    state: {
        id: null,
        price: null,
        limit_count_daily: null,
        today_count: null,
        product_list: [],
        order_list: [],
    },
    mutations: {
        set(state, data) {
            state.id = data.activity_id;
            state.price = data.price;
            state.limit_count_daily = data.limit_count_daily;
            state.today_count = data.today_count;
            state.product_list = data.activity_products;
            state.order_list = data.activity_orders;
        },
        remove(state) {
            state.id = null;
            state.price = null;
            state.limit_count_daily = null;
            state.today_count = null;
            state.product_list = [];
            state.order_list = [];
        },
        cutTodayCount(state, data) {
            state.today_count += data;
        }
    },
    actions: {
        get({commit}, data) {
            return activity().then(res => {
                if( res.code == 30000 ) {
                    if( res.data == null ) {
                        commit("remove");
                    } else {
                        commit("set", res.data);
                    }
                    return Promise.resolve(res.data);
                } else {
                    commit("remove");
                    return Promise.reject(res.msg);
                }
            }).catch(err => {
                commit("remove");
                return Promise.reject(err);
            });
        },
        cutTodayCount({commit}, data) {
            commit("cutTodayCount", data);
        }
    },
    getters: {
        id: (state) => state.id,
        price: (state) => state.price,
        limit_count_daily: (state) => state.limit_count_daily,
        today_count: (state) => state.today_count,
        product_list: (state) => state.product_list,
        order_list: (state) => state.order_list,
    }
}