<template>
    <div class="component-layout-banner">
        <a-carousel arrows autoplay>
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <LeftOutlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <RightOutlined />
              </div>
            </template>
            <img v-for="src in list[device]" :src="src" />
        </a-carousel>
    </div>
</template>

<script>
  import './banner.scss';
  import { ref, onMounted, onUnmounted } from 'vue';
  import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
  import { carousels } from '@/api-core/api-core';

  export default {
        name: 'Banner',
        components: {
            LeftOutlined, RightOutlined
        },

        setup() {
            const device = ref('');
            const list = ref({ PC: [], Mobile: [] });

            const onGet = (type) => {
                if (list.value[type].length < 1) {
                    carousels({type: type}).then(res => {
                        for (let key in res.data) {
                            list.value[type].push(process.env.VUE_APP_STATIC_URL + "/images/carousel/" + res.data[key].path);
                        }
                    });
                }
            };

            const onResize = () => {
                device.value = window.innerWidth > 767 ? 'PC' : 'Mobile';
                onGet(device.value);
            }

            onMounted(() => {
                window.addEventListener('resize', onResize);
                onResize();
            });

            onUnmounted(() => {
                window.removeEventListener('resize', onResize);
            });

            return {
                list, device
            }
        },
    };

</script>