<template>
	<a-menu :mode="mode" justify="center" class="component-layout-menu">
		<template v-if="mode == 'inline'">
			<a-menu-item>
				<img src="@/assets/logo1.png" />
				<CloseOutlined class="close" @click="onClose" />
			</a-menu-item>
			<a-menu-item>
                <a-row justify="space-between">
                    <a-col>主選單</a-col>
                    <a-col class="point">
                        <router-link to="/user/point">
                            可用點數 {{ user.balance }}
                        </router-link>
                    </a-col>
                </a-row>
				<hr />
			</a-menu-item>
		</template>

        <a-menu-item>
            <a class="active" href="/">首頁</a>
        </a-menu-item>

        <a-menu-item v-for="item in menu">
            <router-link :class="cate_id == item.id ? 'active' : ''" :to="`/?cate_id=${ item.id }`" @click="onClose">{{ item.name }}</router-link>
        </a-menu-item>

        <template v-if="mode == 'inline'">
			<a-menu-item>
				<hr />
			</a-menu-item>
			<a-menu-item>
				<router-link to="/user" @click="onClose">
					<a-button class="user">會員資料</a-button>
				</router-link>
			</a-menu-item>

            <div class="service_list">
                <a-row class="title item">規則條款</a-row>
                <a-row class="item">
				    <router-link to="/about" class="text" @click="onClose">關於我們</router-link>
                </a-row>
                <a-row class="item">
                    <router-link to="/points_rule" class="text" @click="onClose">紅利點數規則</router-link>
                </a-row>
                <a-row class="item">
                    <router-link to="/buy_note" class="text" @click="onClose">購物須知</router-link>
                </a-row>
                <a-row class="item">
                    <router-link to="/product_return" class="text" @click="onClose">退換貨須知</router-link>
                </a-row>
                <a-row class="item">
                    <router-link to="/privacy_policy" class="text" @click="onClose">隱私權保護政策</router-link>
                </a-row>
            </div>
		</template>
    </a-menu>
</template>

<script>
    import './menu.scss';
    import { ref, watch, reactive, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { CloseOutlined } from '@ant-design/icons-vue';
    import { cates } from '@/api-core/api-core';

    export default {
        name: 'Menu',
        props: ['mode'],
        components: {
        	CloseOutlined,
        },
        setup(props, context) {
            const store = useStore();
            const user = reactive({
                balance : computed(() => store.state.user.balance),
            });
            const menu = ref([]);
        	const onClose = () => {
        		context.emit('onHide');
        	};

            const route = useRoute();

            const cate_id = ref(0);

            watch(() => route.query.cate_id, (n, o) => {
                document.querySelectorAll('.component-layout-menu li .active').forEach( ele => {
                    ele.classList.remove('active');
                });
                cate_id.value = n;
            });

            onMounted(() => {
                cates().then(res => {
                    menu.value = res.data;
                });
            });

        	return {
        		menu, user, onClose, cate_id,
        	};
        },
    };
</script>