<template>
	<div class="component-layout-footer-service">
		<a-row class="list">
			<a-col class="item">
				<router-link to="/about" class="text">關於我們</router-link>
			</a-col>
			<a-col class="item">
				<router-link to="/points_rule" class="text">紅利點數規則</router-link>
			</a-col>
			<a-col class="item">
				<router-link to="/buy_note" class="text">購物須知</router-link>
			</a-col>
			<a-col class="item">
				<router-link to="/product_return" class="text">退換貨須知</router-link>
			</a-col>
			<a-col class="item">
				<router-link to="/privacy_policy" class="text">隱私權保護政策</router-link>
			</a-col>
		</a-row>
		
		<hr />

		<div class="copyright">
			Copyright <span>威樂紅利商城</span> All Rights Reserved.
		</div>
	</div>
</template>

<script>
	import './service.scss';

	export default {
		name: 'Service',
	}
</script>