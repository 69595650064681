export default {
  "breadcrumb": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的帳戶"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查詢訂單"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紅利紀錄"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新個人資訊"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購物車"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於我們"])},
    "buy_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購物須知"])},
    "product_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退換貨須知"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權保護政策"])},
    "points_rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紅利點數規則"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
    "win_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中獎紀錄"])},
    "redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換資料"])}
  },
  "pointsDetail": {
    "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贈點"])},
    "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣點"])},
    "type3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紅包"])},
    "type4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換"])},
    "type5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消訂單"])}
  },
  "order": {
    "status1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未處理"])},
    "status2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["處理中"])},
    "status3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
    "status4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])}
  }
}