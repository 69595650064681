import { member } from '@/api-core/api-core';

export default {
    namespaced: true,
    state: {
        id: '',
        account: '',
        name: '',
        phone_number: '',
        address: '',
        balance: '',
        token: '',
        urlToken: '',
    },
    mutations: {
        setUser(state, data) {
            state.id = data.id || '';
            state.account = data.account || '';
            state.name = data.name || '';
            state.phone_number = data.phone_number || '';
            state.address = data.address || '';
            state.balance = data.balance || 0;
        },
        removeUser(state) {
            state.id = '';
            state.account = '';
            state.name = '';
            state.phone_number = '';
            state.address = '';
            state.balance = 0;
        },
        setToken(state, data) {
            state.token = data.token;
        },
        setUrlToken(state, data) {
            state.urlToken = data.urlToken;
        }
    },
    actions: {
        member({commit}, data) {
            return member(data).then(res => {
                if( res.code == 30000 ) {
                    commit("setUser", res.data);
                    return Promise.resolve(res.data);
                } else {
                    commit("removeUser");
                    return Promise.reject(res.msg);
                }
            }).catch(err => {
                commit("removeUser");
                return Promise.reject(err);
            });
        },
        setToken({commit}, data) {
            commit("setToken", data);
        },
        setUrlToken({commit}, data) {
            commit("setUrlToken", data);
        }
    },
    getters: {
        
    }
}