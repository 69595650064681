import { getCart } from '@/api-core/api-core';

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        set(state, data) {
            state.list = data || [];
        },
        remove(state) {
            state.list = [];
        }
    },
    actions: {
        get({commit}, data) {
            return getCart(data).then(res => {
                if( res.code == 30000 ) {
                    commit("set", res.data);
                    return Promise.resolve(res.data);
                } else {
                    commit("remove");
                    return Promise.reject(res.msg);
                }
            }).catch(err => {
                commit("remove");
                return Promise.reject(err);
            });
        },
    },
    getters: {
        
    }
}