<template>
    <div class="component-layout-footer">
        <a-row>
            <a-col :span="8">
                <a href="/">
                    <img src="@/assets/home.svg" />
                </a>
            </a-col>
            <a-col :span="8">
                <router-link to="/car">
                    <a-badge :count="car.length">
                        <img src="@/assets/car.svg" />
                    </a-badge>
                </router-link>
            </a-col>
            <a-col :span="8">
                <router-link to="/user">
                    <img src="@/assets/user.svg" />
                </router-link>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import './footer.scss';
    import { reactive, computed } from 'vue';
    import { useStore } from 'vuex';

    export default {
        name: 'Footer',
        components: {
        },

        setup() {
            const store = useStore();
            const car = reactive(computed(() => store.state.car.list || []));

            return {
                car
            };
        }
    };
</script>