import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/components/layouts/main/main';
import Home from '@/components/home/home';

const routes = [
    {
        path: '/maintain',
        name: 'Maintain',
        component: () => import("@/components/maintain/maintain"),
    },
    {
        path: '/',
        name: 'Main',
        component: Main,
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
            },
            {
                path: '/user',
                component: () => import("@/components/user/user"),
            },
            {
                path: '/user/order',
                component: () => import("@/components/user/order"),
            },
            {
                path: '/user/point',
                component: () => import("@/components/user/point"),
            },
            {
                path: '/user/edit',
                component: () => import("@/components/user/edit"),
            },
            {
                path: '/car',
                component: () => import("@/components/product/car"),
            },
            {
                path: '/about',
                component: () => import("@/components/basic/about"),
            },
            {
                path: '/buy_note',
                component: () => import("@/components/basic/buy_note"),
            },
            {
                path: '/product_return',
                component: () => import("@/components/basic/product_return"),
            },
            {
                path: "/points_rule",
                component: () => import("@/components/basic/points_rule"),
            },
            {
                path: '/privacy_policy',
                component: () => import("@/components/basic/privacy_policy"),
            },
            {
                path: '/activity',
                component: () => import("@/components/activity/activity"),
            },
            {
                path: '/activity/win_record',
                component: () => import("@/components/activity/win_record"),
            },
            {
                name: 'Redeem',
                path: '/activity/win_record/redeem',
                component: () => import("@/components/activity/redeem"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        let ele = document.getElementsByClassName('ant-layout-content');
        if (ele.length > 0 && to.path != from.path) {
            ele[0].scrollTop = 0;
        }
        return { top: 0 };
    },
});

export default router
