<template>
    <div class="component-layout-fixed" v-if="path != '/activity'">
        <div>
            <a href="https://www.welove777.com/" target="_blank">
                <img src="@/assets/home.png" />
                <br />
                返回首頁
            </a>
        </div>
        <div>
            <a href="https://line.me/R/ti/p/@244btzhc" target="_blank">
                <img src="@/assets/service.png" />
                <br />
                聯繫客服
            </a>
        </div>
        <div class="activityBtn" v-if="activity_id != null">
            <router-link to="/activity">
            <!-- <a href="#"> -->
                <img src="@/assets/activity_game_present_start.png" />
                <br />
                活動
            <!-- </a> -->
            </router-link>
        </div>
        <div>
            <a href="#" @click="goTop">
                <img src="@/assets/up_arrow.svg" />
            </a>
        </div>
    </div>
</template>

<script>
    import './fixed.scss';
    import { computed, watch } from 'vue';
	import { useStore } from 'vuex';
    import { useRoute, useRouter } from 'vue-router';

    export default {
        name: 'Fixed',

        setup() {
			const store = useStore();
            const route = useRoute();
            const router = useRouter();
            const path = computed(() =>route.path);

            const activity_id = computed(() => store.getters["activity/id"]);

            const goTop = () => {
                document.getElementsByClassName("ant-layout-content")[0].scrollTop = 0;
            };

            const goActivity = () => {
                router.push({ path: '/activity' });
            };

            return {
                goTop, path, goActivity, activity_id,
            };
        },
    };
</script>